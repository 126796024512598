// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Grid from "../vanillaui/atoms/Grid.re.mjs";
import * as React from "react";
import * as Footer from "../organisms/Footer.re.mjs";
import * as LeagueNav from "../organisms/LeagueNav.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GlobalQuery from "../shared/GlobalQuery.re.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.re.mjs";
import * as LeagueLayoutQuery_graphql from "../../__generated__/LeagueLayoutQuery_graphql.re.mjs";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

import '../../global/static.css'
;

var convertVariables = LeagueLayoutQuery_graphql.Internal.convertVariables;

var convertResponse = LeagueLayoutQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = LeagueLayoutQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, LeagueLayoutQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, LeagueLayoutQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(LeagueLayoutQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(LeagueLayoutQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(LeagueLayoutQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(LeagueLayoutQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ts(prim0, prim1) {
  return Caml_splice_call.spliceApply(t, [
              prim0,
              prim1
            ]);
}

function LeagueLayout$Layout(props) {
  var viewer = Core__Option.map(props.viewer, (function (v) {
          return v.fragmentRefs;
        }));
  return JsxRuntime.jsx(GlobalQuery.Provider.make, {
              value: viewer,
              children: JsxRuntime.jsxs(Grid.make, {
                    cols: 1,
                    children: [
                      JsxRuntime.jsx(React.Suspense, {
                            children: Caml_option.some(JsxRuntime.jsx(LeagueNav.make, {
                                      query: props.query
                                    })),
                            fallback: "..."
                          }),
                      props.children,
                      JsxRuntime.jsx(Footer.make, {})
                    ]
                  })
            });
}

var Layout = {
  ts: ts,
  make: LeagueLayout$Layout
};

function LeagueLayout(props) {
  var query = ReactRouterDom.useLoaderData();
  var match = usePreloaded(query.data);
  return JsxRuntime.jsx(LeagueLayout$Layout, {
              children: JsxRuntime.jsx(ReactRouterDom.Outlet, {}),
              query: match.fragmentRefs,
              viewer: match.viewer
            });
}

var make = LeagueLayout;

export {
  Query ,
  Layout ,
  make ,
}
/*  Not a pure module */
