// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Layout from "../shared/Layout.re.mjs";
import * as LoginLink from "../molecules/LoginLink.re.mjs";
import * as LangSwitch from "../molecules/LangSwitch.re.mjs";
import * as LogoutLink from "../molecules/LogoutLink.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as LangProvider from "../shared/LangProvider.re.mjs";
import * as Core from "@linaria/core";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavigationMenu from "../ui/navigation-menu";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.re.mjs";
import * as LeagueNav_query_graphql from "../../__generated__/LeagueNav_query_graphql.re.mjs";
import * as LeagueNav_viewer_graphql from "../../__generated__/LeagueNav_viewer_graphql.re.mjs";
import * as Outline from "@heroicons/react/24/outline";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

var convertFragment = LeagueNav_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LeagueNav_query_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LeagueNav_query_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = LeagueNav_viewer_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(LeagueNav_viewer_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LeagueNav_viewer_graphql.node, convertFragment$1);
}

var ViewerFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function LeagueNav$Viewer(props) {
  var viewer = use$1(props.viewer);
  return Core__Option.getOr(Core__Option.flatMap(viewer.user, (function (user) {
                    return Core__Option.map(user.lineUsername, (function (lineUsername) {
                                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: lineUsername
                                                    }),
                                                " ",
                                                JsxRuntime.jsx(LogoutLink.make, {})
                                              ]
                                            });
                                }));
                  })), JsxRuntime.jsx(LoginLink.make, {}));
}

var Viewer = {
  make: LeagueNav$Viewer
};

var make = NavigationMenu.MenuInstance;

var MenuInstance = {
  make: make
};

function LeagueNav(props) {
  var query = use(props.query);
  Core__Option.getOr(Core__Option.map(query.viewer, (function (viewer) {
              return JsxRuntime.jsx(React.Suspense, {
                          children: Caml_option.some(JsxRuntime.jsx(LeagueNav$Viewer, {
                                    viewer: viewer.fragmentRefs
                                  })),
                          fallback: "..."
                        });
            })), JsxRuntime.jsx(LoginLink.make, {}));
  var params = ReactRouterDom.useParams();
  var slug = params.activitySlug;
  var root = slug !== undefined && slug !== "" ? "/league/" + slug + "/" : "/";
  var navigation = [
    {
      name: t`Rankings`,
      href: root
    },
    {
      name: t`Find Games`,
      href: root + "games"
    },
    {
      name: t`About`,
      href: root + "about"
    }
  ];
  var userNavigation = Core__Option.getOr(Core__Option.flatMap(query.viewer, (function (viewer) {
              return Core__Option.map(viewer.user, (function (user) {
                            return [
                                    {
                                      name: t`Your Profile`,
                                      href: "/league/p/" + user.id
                                    },
                                    {
                                      name: t`Logout`,
                                      href: "/logout"
                                    }
                                  ];
                          }));
            })), []);
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsx("header", {
                              children: JsxRuntime.jsx("nav", {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(React$1.Disclosure, {
                                                as: "nav",
                                                className: "border-b border-gray-200 bg-white",
                                                children: (function (param) {
                                                    return JsxRuntime.jsxs(Layout.Container.make, {
                                                                children: [
                                                                  JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          JsxRuntime.jsxs("div", {
                                                                                children: [
                                                                                  JsxRuntime.jsx("div", {
                                                                                        children: JsxRuntime.jsx(LangSwitch.make, {}),
                                                                                        className: "flex flex-shrink-0 sm:hidden items-center px-1 pt-1 text-sm font-medium"
                                                                                      }),
                                                                                  JsxRuntime.jsx("div", {
                                                                                        children: navigation.map(function (item) {
                                                                                              return JsxRuntime.jsx(LangProvider.Router.NavLink.make, {
                                                                                                          to: item.href,
                                                                                                          children: item.name,
                                                                                                          className: (function (param) {
                                                                                                              return Core.cx(param.isActive ? "border-leaguePrimary text-gray-900" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700", "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium");
                                                                                                            })
                                                                                                        }, item.name);
                                                                                            }),
                                                                                        className: "hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
                                                                                      })
                                                                                ],
                                                                                className: "flex"
                                                                              }),
                                                                          JsxRuntime.jsxs("div", {
                                                                                children: [
                                                                                  JsxRuntime.jsx(LangSwitch.make, {}),
                                                                                  JsxRuntime.jsxs("button", {
                                                                                        children: [
                                                                                          JsxRuntime.jsx("span", {
                                                                                                className: "absolute -inset-1.5"
                                                                                              }),
                                                                                          JsxRuntime.jsx("span", {
                                                                                                children: t`View notifications`,
                                                                                                className: "sr-only"
                                                                                              }),
                                                                                          JsxRuntime.jsx(Outline.BellIcon, {
                                                                                                className: "h-6 w-6",
                                                                                                "aria-hidden": "true"
                                                                                              })
                                                                                        ],
                                                                                        className: "relative rounded-full bg-white ml-3 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
                                                                                        type: "button"
                                                                                      }),
                                                                                  JsxRuntime.jsxs(React$1.Menu, {
                                                                                        as: "div",
                                                                                        className: "relative ml-3",
                                                                                        children: [
                                                                                          Core__Option.getOr(Core__Option.flatMap(query.viewer, (function (viewer) {
                                                                                                      return Core__Option.map(viewer.user, (function (user) {
                                                                                                                    return JsxRuntime.jsx("div", {
                                                                                                                                children: JsxRuntime.jsxs(React$1.MenuButton, {
                                                                                                                                      className: "relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
                                                                                                                                      children: [
                                                                                                                                        JsxRuntime.jsx("span", {
                                                                                                                                              className: "absolute -inset-1.5"
                                                                                                                                            }),
                                                                                                                                        JsxRuntime.jsx("span", {
                                                                                                                                              children: t`Open user menu`,
                                                                                                                                              className: "sr-only"
                                                                                                                                            }),
                                                                                                                                        Core__Option.getOr(Core__Option.map(user.picture, (function (picture) {
                                                                                                                                                    return JsxRuntime.jsx("img", {
                                                                                                                                                                className: "h-8 w-8 rounded-full",
                                                                                                                                                                alt: t`Profile picture`,
                                                                                                                                                                src: picture
                                                                                                                                                              });
                                                                                                                                                  })), null)
                                                                                                                                      ]
                                                                                                                                    })
                                                                                                                              });
                                                                                                                  }));
                                                                                                    })), JsxRuntime.jsx(LoginLink.make, {})),
                                                                                          JsxRuntime.jsx(React$1.Transition, {
                                                                                                enter: "transition ease-out duration-200",
                                                                                                enterFrom: "transform opacity-0 scale-95",
                                                                                                enterTo: "transform opacity-100 scale-100",
                                                                                                leave: "transition ease-in duration-75",
                                                                                                leaveFrom: "transform opacity-100 scale-100",
                                                                                                leaveTo: "transform opacity-0 scale-95",
                                                                                                children: JsxRuntime.jsx(React$1.MenuItems, {
                                                                                                      className: "absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                                                                                                      children: userNavigation.map(function (item) {
                                                                                                            return JsxRuntime.jsx(React$1.MenuItem, {
                                                                                                                        children: (function (param) {
                                                                                                                            var focus = param.focus;
                                                                                                                            return JsxRuntime.jsx(LangProvider.Router.NavLink.make, {
                                                                                                                                        to: item.href,
                                                                                                                                        children: item.name,
                                                                                                                                        className: (function (param) {
                                                                                                                                            return Core.cx(focus ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700");
                                                                                                                                          })
                                                                                                                                      });
                                                                                                                          })
                                                                                                                      }, item.name);
                                                                                                          })
                                                                                                    })
                                                                                              })
                                                                                        ]
                                                                                      })
                                                                                ],
                                                                                className: "hidden sm:ml-6 sm:flex sm:items-center"
                                                                              }),
                                                                          JsxRuntime.jsx("div", {
                                                                                children: JsxRuntime.jsxs(React$1.DisclosureButton, {
                                                                                      className: "relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
                                                                                      children: [
                                                                                        JsxRuntime.jsx("span", {
                                                                                              className: "absolute -inset-0.5"
                                                                                            }),
                                                                                        JsxRuntime.jsx("span", {
                                                                                              children: t`Open main menu`,
                                                                                              className: "sr-only"
                                                                                            }),
                                                                                        param.open ? JsxRuntime.jsx(Outline.XMarkIcon, {
                                                                                                className: "block h-6 w-6",
                                                                                                "aria-hidden": "true"
                                                                                              }) : JsxRuntime.jsx(Outline.Bars3Icon, {
                                                                                                className: "block h-6 w-6",
                                                                                                "aria-hidden": "true"
                                                                                              })
                                                                                      ]
                                                                                    }),
                                                                                className: "-mr-2 flex items-center sm:hidden"
                                                                              })
                                                                        ],
                                                                        className: "flex h-16 justify-between"
                                                                      }),
                                                                  JsxRuntime.jsx(React$1.DisclosurePanel, {
                                                                        className: "sm:hidden",
                                                                        children: (function (param) {
                                                                            var close = param.close;
                                                                            return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                                        children: [
                                                                                          JsxRuntime.jsx("div", {
                                                                                                children: navigation.map(function (item) {
                                                                                                      return JsxRuntime.jsx(LangProvider.Router.NavLink.make, {
                                                                                                                  to: item.href,
                                                                                                                  children: item.name,
                                                                                                                  className: (function (param) {
                                                                                                                      return Core.cx(param.isActive ? "border-red-500 bg-red-50 text-red-700" : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800", "block border-l-4 py-2 pl-3 pr-4 text-base font-medium");
                                                                                                                    }),
                                                                                                                  onClick: (function (param) {
                                                                                                                      close();
                                                                                                                    })
                                                                                                                }, item.name);
                                                                                                    }),
                                                                                                className: "space-y-1 pb-3 pt-2"
                                                                                              }),
                                                                                          JsxRuntime.jsxs("div", {
                                                                                                children: [
                                                                                                  Core__Option.getOr(Core__Option.flatMap(query.viewer, (function (viewer) {
                                                                                                              return Core__Option.map(viewer.user, (function (user) {
                                                                                                                            return JsxRuntime.jsxs("div", {
                                                                                                                                        children: [
                                                                                                                                          JsxRuntime.jsx("div", {
                                                                                                                                                children: Core__Option.getOr(Core__Option.map(user.picture, (function (picture) {
                                                                                                                                                            return JsxRuntime.jsx("img", {
                                                                                                                                                                        className: "h-10 w-10 rounded-full",
                                                                                                                                                                        alt: t`Profile picture`,
                                                                                                                                                                        src: picture
                                                                                                                                                                      });
                                                                                                                                                          })), null),
                                                                                                                                                className: "flex-shrink-0"
                                                                                                                                              }),
                                                                                                                                          JsxRuntime.jsxs("div", {
                                                                                                                                                children: [
                                                                                                                                                  JsxRuntime.jsx("div", {
                                                                                                                                                        children: Core__Option.getOr(user.lineUsername, ""),
                                                                                                                                                        className: "text-base font-medium text-gray-800"
                                                                                                                                                      }),
                                                                                                                                                  JsxRuntime.jsx("div", {
                                                                                                                                                        children: "",
                                                                                                                                                        className: "text-sm font-medium text-gray-500"
                                                                                                                                                      })
                                                                                                                                                ],
                                                                                                                                                className: "ml-3"
                                                                                                                                              }),
                                                                                                                                          JsxRuntime.jsxs("button", {
                                                                                                                                                children: [
                                                                                                                                                  JsxRuntime.jsx("span", {
                                                                                                                                                        className: "absolute -inset-1.5"
                                                                                                                                                      }),
                                                                                                                                                  JsxRuntime.jsx("span", {
                                                                                                                                                        children: t`View notifications`,
                                                                                                                                                        className: "sr-only"
                                                                                                                                                      }),
                                                                                                                                                  JsxRuntime.jsx(Outline.BellIcon, {
                                                                                                                                                        className: "h-6 w-6",
                                                                                                                                                        "aria-hidden": "true"
                                                                                                                                                      })
                                                                                                                                                ],
                                                                                                                                                className: "relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
                                                                                                                                                type: "button"
                                                                                                                                              })
                                                                                                                                        ],
                                                                                                                                        className: "flex items-center px-4"
                                                                                                                                      });
                                                                                                                          }));
                                                                                                            })), null),
                                                                                                  JsxRuntime.jsx("div", {
                                                                                                        children: Core__Option.getOr(Core__Option.flatMap(query.viewer, (function (viewer) {
                                                                                                                    return Core__Option.map(viewer.user, (function (param) {
                                                                                                                                  return userNavigation.map(function (item) {
                                                                                                                                              return JsxRuntime.jsx(LangProvider.Router.NavLink.make, {
                                                                                                                                                          to: item.href,
                                                                                                                                                          children: item.name,
                                                                                                                                                          className: (function (param) {
                                                                                                                                                              return "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800";
                                                                                                                                                            }),
                                                                                                                                                          onClick: (function (param) {
                                                                                                                                                              close();
                                                                                                                                                            })
                                                                                                                                                        }, item.name);
                                                                                                                                            });
                                                                                                                                }));
                                                                                                                  })), JsxRuntime.jsx(LoginLink.make, {})),
                                                                                                        className: "mt-3 space-y-1"
                                                                                                      })
                                                                                                ],
                                                                                                className: "border-t border-gray-200 pb-3 pt-4"
                                                                                              })
                                                                                        ]
                                                                                      });
                                                                          })
                                                                      })
                                                                ],
                                                                className: ""
                                                              });
                                                  })
                                              }),
                                          className: "min-h-full"
                                        })
                                  })
                            });
                })
            });
}

var make$1 = LeagueNav;

var $$default = LeagueNav;

export {
  Fragment ,
  ViewerFragment ,
  Viewer ,
  MenuInstance ,
  make$1 as make,
  $$default as default,
}
/*  Not a pure module */
